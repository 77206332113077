import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"
import { Link } from "gatsby"
import { Button, Col, Container, Div, Row, StyleReset, Text, ThemeProvider } from "atomize"
import Header from "../components/header"
import SEO from "../components/seo"
import Footer from "../components/footer"
import config, { theme } from "../settings/config"
import PostList from "../components/postList"
import { motion } from "framer-motion"
import BoldText from "../components/BoldText"
import SubheaderText from "../components/SubheaderText"
import CoverWave from "../images/assets/w-come-funziona.svg"

export const PostLink = ({ post }) => (
    <div>
        <Link to={post.frontmatter.slug}>
            {post.frontmatter.title} ({post.frontmatter.date}){post.excerpt}
        </Link>
    </div>
)

function Blog({ data }) {
    const [selectedCategory, setCategory] = useState(null)
    const posts = data.pageQuery.nodes
    const categories = []
    data.categories.nodes.forEach(categoryEntry => {
        const category = categoryEntry.frontmatter.category
        category && !categories[category] && (categories[category] = category)
    })

    return (
        <ThemeProvider theme={theme}>
            <StyleReset />
            <Header />
            <SEO
                title="Il nostro blog antispreco | ricette e consigli"
                description="Ecco a voi il nostro blog antispreco: scopri i nostri consigli alimentari e le nostre ricette antispreco. Ti terremo sempre aggiornato sulle novità della casa!"
                pathname="blog"
            />

            <Div tag="main" style={{ position: "relative", overflow: "hidden" }}>
                <Div
                    tag="section"
                    pos="relative"
                    bg="accent_ulight"
                    zIndex="1"
                    p={{
                        t: {
                            xs: "1rem",
                            sm: "2rem",
                            lg: "8rem",
                            xl: "10rem",
                        },
                    }}
                >
                    <Container>
                        <Text tag="h1" d="none">
                            Resta sempre aggiornato con il nostro blog antispreco
                        </Text>
                        <Text
                            textSize={{
                                xs: "display2",
                                md: "display3",
                            }}
                            textWeight="bold"
                            textAlign={{ lg: "center" }}
                            textColor="gunmetal"
                            m={{ t: { xs: "1rem", lg: "0.5rem" }, b: { xs: "2rem", lg: "0" } }}
                        >
                            Il nostro <BoldText textColor="primary">blog</BoldText>
                        </Text>
                        <SubheaderText
                            textAlign={{ lg: "center" }}
                            m={{ t: { md: "3rem" }, y: { xs: "1rem", md: "0" } }}
                        >
                            Anche qua puoi <BoldText>scegliere</BoldText> quello che preferisci leggere!
                        </SubheaderText>
                        <Div d="flex" justify={{ md: "center" }} overflow="auto" m={{ t: "1rem" }}>
                            {Object.entries(categories).map(([category, index]) => (
                                <motion.div key={index} whileTap={{ scale: 0.9 }}>
                                    <Button
                                        p={{ x: "1.5rem", y: "1rem" }}
                                        m={{ x: "0.5rem", b: "0.5rem" }}
                                        bg={selectedCategory === category ? "gunmetal" : "white"}
                                        shadow="3"
                                        rounded="20px"
                                        onClick={() =>
                                            selectedCategory !== category ? setCategory(category) : setCategory(null)
                                        }
                                    >
                                        <Text
                                            textColor={selectedCategory === category ? "white" : "gunmetal"}
                                            style={{ whiteSpace: "nowrap" }}
                                        >
                                            {category}
                                        </Text>
                                    </Button>
                                </motion.div>
                            ))}
                        </Div>
                    </Container>
                </Div>
                <Div tag="section">
                    <Div m={{ t: { xs: "-1rem", lg: "-7rem" } }}>
                        <CoverWave className="wave" />
                    </Div>
                    <Container>
                        <PostList posts={posts} selectedCategory={selectedCategory} />
                    </Container>
                </Div>
            </Div>

            <Footer />
        </ThemeProvider>
    )
}

export default Blog

export const { pageQuery, categories } = graphql`
    query {
        categories: allMarkdownRemark {
            nodes {
                frontmatter {
                    category
                }
            }
        }
        pageQuery: allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
            nodes {
                excerpt
                frontmatter {
                    slug
                    title
                    description
                    thumbnail
                    category
                    date(formatString: "DD MMMM, YYYY")
                }
                timeToRead
            }
        }
    }
`
